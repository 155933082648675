<template>
  <v-container
    id="verifyemail"
    class="fill-height justify-center"
    tag="section"
  >
    <v-card 
      class="mt-16"
      elevation="0"
      max-width="600"
    >
      <v-card-text>
        <p style="line-height: 28px;">
          We've sent you an email from <a href="mailto:info@revampcybersecurity.com">info@revampcybersecurity.com</a> so we can verify
        your email address. Just click on th ebutton to finish your registration creating
        your account If you dont reeive the email please check your spam or junk folder.
        </p>
        <div class="d-flex align-center justify-center">
          Didn't get the email? <v-btn :disabled="loading" :loading="loading" @click="resend" color="primary" text>Resend it now</v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
  import { mapState } from 'vuex'

  export default {
    name: 'VerifyEmail',

    computed: {
      ...mapState('auth', ['loading'])
    },

    methods: {
      resend() {
        this.$store.dispatch('auth/resendEmail')
      }
    }
  }
</script>